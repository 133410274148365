@use '../../app.scss' as *;

.about {
  &__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      grid-template-columns: 1.6fr 2fr;
    }

    /* For large to medium devices ≤ 768px */
    @media screen and (max-width: 920px) {
      grid-template-columns: 1fr;
      row-gap: 2.5rem;
    }
  }

  &__me {
    position: relative;
    justify-self: center;

    /* For medium devices ≤ 768px */
    @media screen and (max-width: 920px) {
      margin-bottom: 0.7rem;
    }

    &:hover &--bg {
      transform: rotate(0);
    }

    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 330px;
      width: 330px;
      border-radius: 1.5rem;
      background: linear-gradient(
        45deg,
        transparent,
        $title-color,
        transparent
      );
      transform: rotate(10deg);
      transition: $transition;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        height: 300px;
        width: 300px;
      }

      /* For medium devices ≤ 768px */
      @media screen and (max-width: 920px) {
        height: 220px;
        width: 220px;
      }
    }
  }

  &__img {
    position: relative;
    width: 330px;
    border-radius: 1.5rem;
    filter: grayscale(30%);

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      width: 300px;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: 920px) {
      width: 220px;
    }
  }

  &__data {
    /* For extra large devices */
    @media screen and (max-width: 920px) {
      text-align: center;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 140px);
    gap: 1.5rem;
    margin-bottom: $mb-2;

    /* For medium devices ≤ 768px */
    @media screen and (max-width: 920px) {
      justify-content: center;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 5rem;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      padding: 0 1rem;
    }

    /* For extra small devices ≤ 350px */
    @media screen and (max-width: $xs) {
      gap: 1rem;
    }
  }

  &__box {
    background: $container-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
    transition: $transition;

    &:hover {
      @include boxHover;
    }

    /* For large devices ≥ 921px */
    @media screen and (min-width: 921px) {
      &:last-child {
        display: none;
      }
    }

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      padding: 0.75rem 0.5rem;
    }
  }

  &__icon {
    font-size: 1.5rem;
    color: $title-color;
    margin-bottom: $mb-0-5;
  }

  &__title {
    font-size: $small-font-size;
    font-weight: $font-medium;
  }

  &__subtitle {
    font-size: $tiny-font-size;
    color: $primary-text-color;
  }

  &__description {
    padding: 0 4rem 0 0;
    margin-bottom: $mb-2-5;
    color: $primary-text-color;

    /* For medium devices ≤ 768px */
    @media screen and (max-width: 920px) {
      padding: 0 5rem;
      margin-bottom: 2rem;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      padding: 0 1rem;
    }
  }
}
