/* ========== GOOGLE FONTS ========== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

/* ========== VARIABLES SCSS ========== */
$header-height: 3rem;

$transition: all 400ms ease;

:root {
  .main {
    --hue: 0;
    --sat: 0%;
    --section-color: hsla(var(--hue), var(--sat), 100%, 1);
    --title-color: hsla(var(--hue), var(--sat), 20%, 1);
    --title-color-dark: hsl(var(--hue), var(--sat), 15%, 1);
    --text-color: hsla(var(--hue), var(--sat), 46%, 1);
    --primary-text-color: hsla(var(--hue), var(--sat), 0%, 1);
    --primary-border-color: hsla(var(--hue), var(--sat), 70%, 1);
    --body-color: hsla(var(--hue), var(--sat), 98%, 1);
    --container-color: hsla(var(--hue), var(--sat), 100%, 1);
  }
}

/* ========== Colors ========== */
/* Color mode HSL(hue, saturation, lightness) */
$hue: var(--hue);
$sat: var(--sat);
$title-color: var(--title-color);
$title-color-dark: var(--title-color-dark);
$text-color: var(--text-color);
$primary-text-color: var(--primary-text-color);
$primary-border-color: var(--primary-border-color);
$body-color: var(--body-color);
$container-color: var(--container-color);
$section-color: var(--section-color);
$color-white: #fff;
/* ===== ======= */

/* Theme change using react */

.main.color-1 {
  --hue: 0;
  --sat: 0%;
}

.main.color-2 {
  --hue: 110;
  --sat: 70%;
}

.main.color-3 {
  --hue: 240;
  --sat: 70%;
}

.main.color-4 {
  --hue: 325;
  --sat: 70%;
}

.main.color-5 {
  --hue: 0;
  --sat: 70%;
}

.main.color-6 {
  --hue: 55;
  --sat: 70%;
}

/* === Background color switch === */
.main.bg-2 {
  --title-color: hsla(var(--hue), var(--sat), 100%, 1);
  --title-color-dark: hsl(var(--hue), var(--sat), 70%, 1);
  --section-color: hsla(var(--hue), var(--sat), 5%, 1);
  --body-color: hsla(var(--hue), var(--sat), 15%, 1);
  --primary-text-color: hsla(var(--hue), var(--sat), 95%, 1);
  --primary-border-color: hsla(var(--hue), var(--sat), 30%, 1);
  --container-color: hsla(var(--hue), var(--sat), 15%, 1);
}

@mixin boxHover {
  background: transparent;
  border-color: $container-color;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(-0.5rem);
}

/* ========== DEVICE BREAKPOINTS ========== */
$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 360px;

/* ========== Font and typography ========== */
$body-font: 'Poppins', sans-serif;
//$body-font: 'Montserrat Alternates', sans-serif;
//$body-font: 'Montserrat', sans-serif;

/* .5rem = 8px | 1rem = 16px ... */
$big-font-size: 3.5rem;
$h1-font-size: 2.25rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$normal-font-size: 1rem;
$small-font-size: 0.875rem;
$smaller-font-size: 0.813rem;
$tiny-font-size: 0.625rem;

/* === Responsive typography === */
@media screen and (max-width: 992px) {
  $big-font-size: 2.75rem;
  $h1-font-size: 1.5rem;
  $h2-font-size: 1.25rem;
  $h3-font-size: 1rem;
  $normal-font-size: 0.938rem;
  $small-font-size: 0.813rem;
  $smaller-font-size: 0.75rem;
}

@media screen and (max-width: $sm) {
  $big-font-size: 2.25rem;
}

/* ========== Font weight ========== */
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-black: 900;

/* ========== Margin Bottom ========== */
/* .5rem = 8px | 1rem = 16px ... */
$mb-0-25: 0.25rem;
$mb-0-5: 0.5rem;
$mb-0-75: 0.75rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;
$mb-3: 3rem;
$mb-3-5: 3.5rem;

/* ========== z-index ========== */
$z-tooltip: 10;
$z-fixed: 100;
$z-modal: 1000;

/* =========== BASE =========== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: $body-font;
  font-size: $normal-font-size;
}

body {
  background-color: $body-color;
  color: $text-color;

  @media screen and (max-width: $lg) {
    margin: 0 0 $header-height 0;
  }
}

h1,
h2,
h3 {
  color: $title-color;
  font-weight: $font-semi-bold;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* === REUSABLE CSS CLASSES === */
.section {
  background: $section-color;
  padding: 6rem 0 2rem;

  @media screen and (max-width: $lg) {
    padding: 2rem 0 4rem;
  }
}

.section__title {
  font-size: $h1-font-size;
  color: $title-color;
}

.section__subtitle {
  display: block;
  font-size: $small-font-size;
  color: $primary-text-color;
  margin-bottom: 4rem;

  @media screen and (max-width: $lg) {
    margin-bottom: $mb-3;
  }
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* ========== LAYOUT ========== */
.container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  /* For extra extra large devices ≤ 1400px */
  @media screen and (max-width: $xxl) {
  }

  /* For extra large devices ≤ 1200px */
  @media screen and (max-width: $xl) {
    max-width: 90%;
  }

  /* For large devices ≤ 992px */
  @media screen and (max-width: $lg) {
  }

  /* For medium devices ≤ 768px */
  @media screen and (max-width: $md) {
  }

  /* For small devices ≤ 576px */
  @media screen and (max-width: $sm) {
  }

  /* For extra small devices < 576px */
  @media screen and (min-width: $xs) {
  }
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* ========= BUTTONS ========= */
.button {
  display: inline-block;
  background-color: $title-color;
  color: $container-color;
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: $font-medium;
  transition: $transition;

  @media screen and (max-width: $xl) {
    padding: 1rem 1.75rem;
  }
}

.button:hover {
  background-color: $title-color-dark;
  transform: scale(1.05);
}

.button__icon {
  margin-left: $mb-0-5;

  @media screen and (max-width: $xl) {
    width: 22px;
    height: 22px;
  }

  path {
    fill: $container-color;
  }
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/* ========= BREAKPOINTS ========= */
/* For extra large devices */
@media screen and (max-width: $xl) {
}

/* For large devices */
@media screen and (max-width: $lg) {
}

/* For medium devices */
@media screen and (max-width: $md) {
}

/* For small devices */
@media screen and (max-width: $sm) {
}

.myspinner span {
  background-color: rgba(225, 225, 225) !important;
}
