@use '../../app.scss' as *;

.scrollup {
  position: fixed;
  right: 2.5rem;
  bottom: -20%;
  background-color: $title-color;
  opacity: 0.8;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  z-index: $z-tooltip;
  transition: 0.4s;

  /* For extra large devices */
  @media screen and (max-width: $xl) {
    right: 1.5rem;
    padding: 0.25rem 0.4rem;
  }

  /* For small devices */
  @media screen and (max-width: $sm) {
    right: 1rem;
  }

  &:hover {
    background-color: $title-color-dark;
  }

  &__icon {
    font-size: 1.5rem;
    color: $container-color;

    /* For extra large devices */
    @media screen and (max-width: $xl) {
      font-size: 1.25rem;
    }
  }
}

/* Show Scroll */
.show-scroll {
  bottom: 3rem;

  /* For large devices */
  @media screen and (max-width: $lg) {
    bottom: 4rem;
  }
}
