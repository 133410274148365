@use '../../app.scss' as *;

.fav-loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: $title-color;
  border-radius: 50%;
  text-align: center;

  svg {
    width: 40px;
  }

  .border-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border: 0.3rem solid $title-color;
    border-radius: 50%;
    animation: pulse-border 0.5s linear infinite forwards;

    &--border-1 {
      animation-delay: 0s;
    }

    &--border-2 {
      animation-delay: 1s;
    }
  }
}
