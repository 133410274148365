@use '../../app.scss' as *;

.testimonial {
  &__container.swiper {
    padding: 0.1rem;
  }

  &__container {
    width: 70%;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      width: 75%;
    }

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      width: 80%;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      width: 96%;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      width: 85%;
    }

    /* For small devices ≤ 350px */
    @media screen and (max-width: $xs) {
      width: 90%;
    }
  }

  &__card {
    background-color: $container-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: $mb-3;
    transition: $transition;

    &:hover {
      @include boxHover;
    }

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      padding: 1.25rem 1.5rem;
    }
  }

  &__img {
    width: 60px;
    border-radius: 50%;
    margin-bottom: $mb-1;
    border: 1px solid $primary-border-color;
  }

  &__name {
    font-size: $normal-font-size;
    font-weight: $font-medium;
    margin-bottom: $mb-0-25;
  }

  &__description {
    color: $primary-text-color;
    font-size: $small-font-size;
    line-height: 1.5rem;
  }

  /* Swiper Class */
  .swiper-pagination-bullet {
    background-color: $text-color;
  }

  .swiper-pagination-bullet-active {
    background-color: $title-color;
  }
}
