@use '../../app.scss' as *;

.qualification {
  &__container {
    max-width: 768px;

    /* For extra large devices */
    @media screen and (max-width: $xl) {
      margin-left: auto;
      margin-right: auto;
    }

    /* For large devices */
    @media screen and (max-width: $lg) {
      margin-left: $mb-1-5;
      margin-right: $mb-1-5;
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: $mb-2;
  }

  &__button {
    font-size: $h3-font-size;
    font-weight: $font-medium;
    color: $title-color;
    margin: 0 $mb-1;
    cursor: pointer;

    /* For medium devices */
    @media screen and (max-width: $md) {
      margin: 0 $mb-0-75;
    }

    &:hover {
      color: $title-color-dark;
      // text-decoration: underline;
      border-bottom: 2px solid $title-color-dark;
    }
  }

  &__icon {
    font-size: 1.8rem;
    margin-right: $mb-0-25;
  }

  &__active {
    color: $title-color-dark;
    border-bottom: 2px solid $title-color-dark;
  }

  &__sections {
    display: grid;
    grid-template-columns: 0.6fr;
    justify-content: center;

    /* For medium devices */
    @media screen and (max-width: $md) {
      grid-template-columns: initial;
    }
  }

  &__content {
    display: none;

    &--active {
      display: block;
    }
  }

  &__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;

    &:not(:last-child) {
      padding-bottom: 1.5rem;
    }

    /* For small devices */
    @media screen and (max-width: $sm) {
      gap: 0.5rem;
    }

    &--left {
      margin-left: auto;
      text-align: right;
    }
  }

  &__title {
    font-size: $normal-font-size;
    font-weight: $font-medium;

    a {
      text-decoration: none;
      color: $title-color;

      &:hover {
        color: $title-color-dark;
      }
    }
  }

  &__subtitle {
    color: $primary-text-color;
    display: inline-block;
    font-size: $small-font-size;
    margin-bottom: $mb-1;
  }

  &__calendar {
    color: $primary-text-color;
    font-size: $small-font-size;
  }

  &__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: $text-color;
    border-radius: 50%;
  }

  &__line {
    display: block;
    width: 1px;
    height: 115%;
    background-color: $text-color;
    transform: translate(6px, -7px);
  }
}
