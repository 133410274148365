@use '../../app.scss' as *;

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-fixed;
  background-color: $body-color;

  /* For large devices ≤ 992px */
  @media screen and (max-width: $lg) {
    top: initial;
    bottom: 0;
  }

  .nav {
    height: calc($header-height + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      height: $header-height;
    }

    &__logo,
    &__toggle {
      color: $title-color;
      font-weight: $font-medium;
    }

    &__logo {
      font-size: $h3-font-size;
      font-weight: $font-bold;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        font-size: $normal-font-size;
      }
    }

    &__menu {
      margin-left: auto;
      z-index: 99;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: $body-color;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(black, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: 0.3s;
        //margin-left: 0;
      }

      /* For small devices ≤ 576px */
      @media screen and (max-width: $sm) {
        padding: 2rem 0.25rem 4rem;
      }
    }

    .theme-wrapper {
      position: relative;
      cursor: pointer;
      margin-left: 20px;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        margin-left: auto;
      }
    }

    &__theme {
      width: 25px;
      height: 25px;
      background: $title-color;
      border-radius: 50%;
      text-align: center;

      &:hover {
        background: $title-color-dark;
      }

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        margin-left: auto;
      }

      &--icon {
        color: $container-color;
      }
    }

    .border-animation {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      border: 0.3rem solid $title-color;
      border-radius: 50%;
      animation: pulse-border 1.5s linear infinite;

      &--border-1 {
        animation-delay: 1s;
      }

      &--border-2 {
        animation-delay: 1.5s;
      }
    }

    // Show Menu
    .show-menu {
      bottom: 0;
    }

    &__toggle {
      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        font-size: 1.1rem;
        cursor: pointer;
      }
    }

    &__list {
      display: flex;
      column-gap: 2rem;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }

      /* For large devices ≤ 576px */
      @media screen and (max-width: $sm) {
        column-gap: 0;
      }

      /* For extra small devices ≤ 350px */
      @media screen and (max-width: $xs) {
        //grid-template-columns: repeat(3, 29%);
      }
    }

    &__item {
      /* For extra small devices ≤ 350px */
      @media screen and (max-width: $xs) {
        //width: max-content;
      }
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: $small-font-size;
      color: $title-color;
      font-weight: $font-medium;
      transition: 0.3s;

      /* For extra small devices ≤ 350px */
      @media screen and (max-width: $xs) {
        font-size: 0.8rem;
      }
    }

    &__icon {
      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        font-size: 1.2rem;
      }
    }

    &__close {
      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        position: absolute;
        right: 1.3rem;
        bottom: 0.5rem;
        cursor: pointer;
        color: $title-color;
      }

      &:hover {
        @media screen and (max-width: $lg) {
          color: $title-color-dark;
        }
      }
    }

    &__icon,
    &__close,
    &__toggle {
      display: none;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        display: block;
      }
    }

    // Active link
    .active-link,
    &__link:hover {
      color: $title-color-dark;
      font-weight: 700;
    }

    .active-link i::before {
      font-weight: 700 !important;
    }
  }
}

/* Change header background */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.2);
}

// PULSE BORDER
@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
