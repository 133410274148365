@use '../../app.scss' as *;

.certification {
  /* For medium devices ≤ 576px */
  @media screen and (max-width: $sm) {
    padding-top: 4rem !important;
  }

  &__container {
    width: 85%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    justify-items: center;
    margin: 0 auto;

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      width: 80%;
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
  }

  &__category {
    font-size: $h3-font-size;
    font-weight: $font-medium;
    color: $title-color;
    margin-bottom: 2rem;
    margin-left: -0.5rem;
    align-items: center;
    display: inline-flex;
  }

  &__icon {
    font-size: 1.5rem;
    margin-right: $mb-0-25;
  }

  &__item {
    border-left: 1px solid $text-color;
    padding-left: 1.6rem;
    position: relative;

    &:not(:last-child) {
      padding-bottom: 2rem;
    }

    &::before {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      position: absolute;
      left: -0.45rem;
      background-color: $text-color;
      margin-top: 0.4rem;
    }
  }

  &__title {
    font-size: $normal-font-size;
    font-weight: $font-medium;
    //line-height: 1;

    /* For medium devices */
    // @media screen and (max-width: $md) {
    //   line-height: 1.5;
    // }
  }

  &__issuer {
    color: $primary-text-color;
    display: inline-block;
    font-size: $small-font-size;
    margin-top: 0.3rem;
  }

  &__date {
    color: $primary-text-color;
    margin-bottom: $mb-1;
    margin-top: 0.3rem;
    font-size: 0.875rem;
  }

  &__button {
    color: $text-color;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;

    &--icon {
      font-size: 1rem;
      transition: 0.3s;
    }

    &:hover {
      color: $title-color;
      font-weight: 500;
    }

    &:hover &--icon {
      transform: translateX(0.25rem);
    }
  }

  &__view-all {
    margin-top: 3rem;
    text-align: center;

    a {
      width: auto;

      .button__icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
