@use '../../app.scss' as ct;

.card.theme__modal {
  width: 35rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #e7e6e6;
  z-index: 2000;
  text-align: center;
  box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.2);
  padding: 4.5rem 3rem 3.5rem 3rem;

  /* For medium devices ≤ 768px */
  @media screen and (max-width: ct.$md) {
    width: 70%;
  }

  /* For small devices ≤ 576px */
  @media screen and (max-width: ct.$sm) {
    width: 90%;
    padding: 3.5rem 2rem 2rem 2rem;
  }

  /* For extra small devices ≤ 350px */
  @media screen and (max-width: ct.$xs) {
    width: 95%;
  }

  small {
    margin-top: 0.6rem;
    display: block;
    width: 80%;
    margin-inline: auto;
  }
}

.theme__primary--wrapper {
  margin: 3rem 0;
}

.theme__primary--colors {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  /* For medium devices */
  @media screen and (max-width: ct.$md) {
    gap: 0.6rem;
  }

  button {
    width: 2rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &.color-1 {
      background: hsl(0, 0%, 20%, 1);
    }

    &.color-1.active-color {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px hsl(0, 0%, 20%, 1);
    }

    &.color-2 {
      background: hsl(110, 70%, 20%, 1);
    }

    &.color-2.active-color {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px hsl(110, 70%, 20%, 1);
    }

    &.color-3 {
      background: hsl(240, 70%, 20%, 1);
    }

    &.color-3.active-color {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px hsl(240, 70%, 20%, 1);
    }

    &.color-4 {
      background: hsl(325, 70%, 20%, 1);
    }

    &.color-4.active-color {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px hsl(325, 70%, 20%, 1);
    }

    &.color-5 {
      background: hsl(0, 70%, 20%, 1);
    }

    &.color-5.active-color {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px hsl(0, 70%, 20%, 1);
    }

    &.color-6 {
      background: hsl(52, 70%, 20%, 1);
    }

    &.color-6.active-color {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px hsl(52, 70%, 20%, 1);
    }
  }
}

.theme__background--colors {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;

  button {
    width: 7rem;
    height: 2.5rem;
    background: white;
    border-radius: 3rem;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &.bg-1.active-color {
      box-shadow: 0 0 0 4px #e7e6e6, 0 0 0 6px #fff;
    }

    &.bg-2 {
      background: #111;
    }

    &.bg-2.active-color {
      box-shadow: 0 0 0 4px #e7e6e6, 0 0 0 6px #111;
    }
  }
}
