@use '../../app.scss' as *;

.home.section {
  /* For extra large devices ≤ 1200px */
  @media screen and (max-width: $xl) {
    padding-top: 4rem;
  }

  /* For extra large devices ≤ 576px */
  @media screen and (max-width: $sm) {
    padding-top: 2rem;
  }
}

.home {
  &__container {
    row-gap: 7rem;

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
    }
  }

  &__content {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      grid-template-columns: 50px repeat(2, 1fr);
      padding-top: 0;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      grid-template-columns: 0.5fr 3fr;
      width: 80%;
      margin: 0 auto;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      grid-template-columns: 0.5fr auto;
      width: 95%;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $xs) {
      grid-template-columns: 0.5fr auto;
      width: 95%;
    }
  }

  &__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;

    &--icon {
      font-size: 1.25rem;
      color: $title-color;
      transition: $transition;

      &:hover {
        color: $title-color-dark;
        transform: scale(1.5);
      }
    }
  }

  &__img {
    background: url(../../assets/images/profile.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    //box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    box-shadow: inset 0 0 0 9px $title-color;
    order: 1;
    justify-self: end;
    width: 350px;
    height: 350px;
    filter: grayscale(30%);
    animation: profile__animate 8s ease-in-out infinite 1s;

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      order: initial;
      justify-self: initial;
      //box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
      box-shadow: inset 0 0 0 6px $title-color;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      width: 280px;
      height: 280px;
      justify-self: end;
    }

    /* For extra small devices ≤ 350px */
    @media screen and (max-width: $xs) {
      width: 250px;
      height: 250px;
    }
  }

  &__data {
    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      grid-column: 1/3;
    }

    .hire-me-btn {
      /* For medium devices ≥ 769px */
      @media screen and (min-width: 769px) {
        display: none;
      }
    }
  }

  &__title {
    font-size: $big-font-size;
    margin-bottom: $mb-0-25;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      font-size: $h1-font-size;
    }
  }

  &__subtitle {
    position: relative;
    font-size: 1.1rem;
    padding-left: 5.4rem;
    font-weight: $font-normal;
    margin-bottom: 0.3rem;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      padding-left: 3rem;
      margin-bottom: 0.3rem;
      font-size: 1.05rem;
    }

    &::before {
      content: '';
      position: absolute;
      width: 70px;
      height: 1px;
      background-color: $text-color;
      left: 0;
      top: 0.85rem;

      /* For extra large devices ≤ 1200px */
      @media screen and (max-width: $xl) {
        width: 32px;
        top: 0.8rem;
      }
    }
  }

  &__subtitle.pm {
    margin-bottom: 1.3rem;
  }

  &__subtitle.pm::before {
    height: 0;
  }

  &__description {
    max-width: 400px;
    margin-bottom: $mb-2;
    color: $primary-text-color;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      max-width: initial;
      margin-bottom: $mb-2-5;
    }

    /* For extra large devices ≤ 576px */
    @media screen and (max-width: $sm) {
      max-width: initial;
      margin-bottom: $mb-2;
    }
  }

  &__cta {
    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      display: flex;
      justify-content: space-between;
    }

    .button {
      @media screen and (max-width: 380px) {
        padding: 0.7rem 1.3rem;
        font-size: $small-font-size;
        border-radius: 0.7rem;
      }
    }
  }

  &__hand {
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
    display: none;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      width: 26px;
      height: 26px;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      width: 22px;
      height: 22px;
    }
  }

  &__scroll {
    margin-left: 9.25rem;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      margin-left: 5rem;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      display: none;
    }

    &__button {
      transition: $transition;

      &--mouse {
        path {
          stroke: $title-color;
        }
      }

      &--name {
        color: $title-color;
        font-weight: $font-medium;
        margin-right: $mb-0-25;
        margin-left: 2px;
      }

      &--arrow {
        font-size: 1.25rem;
        color: $title-color;
      }

      &:hover {
        @include boxHover;
        border: none;
        box-shadow: none;
      }

      &:hover &--name {
        color: $title-color-dark;
      }

      &:hover &--arrow {
        color: $title-color-dark;
      }

      &:hover &--mouse path {
        stroke: $title-color-dark;
      }
    }
  }

  .wheel {
    animation: scroll 2s ease infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateY(0);
    }

    30% {
      transform: translateY(3.75rem);
    }
  }
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
