@use '../../app.scss' as *;

.alert__popup {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 28%;
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  right: -28%;
  top: 5rem;
  z-index: 99999;
  transition: 0.4s;

  /* For medium devices ≤ 1200px */
  @media screen and (max-width: $xl) {
    width: 35%;
    right: -35%;
  }

  /* For medium devices ≤ 768px */
  @media screen and (max-width: $md) {
    width: 60%;
    right: -60%;
    top: 2rem;
  }

  /* For small devices ≤ 576px */
  @media screen and (max-width: $sm) {
    width: 80%;
    right: -80%;
    top: 2rem;
  }

  i {
    font-size: 1.5rem;
  }

  p {
    padding-left: 1rem;
    padding-right: 1rem;

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      font-size: 0.9rem;
    }
  }

  .alert--close {
    align-self: flex-start;
    margin-left: auto;
    cursor: pointer;
  }
}

.alert--success {
  background-color: #d1e7dd;
  border: 1px solid #0f5132;
  color: #0f5132;
}

.alert--danger {
  background-color: #f8d7da;
  border: 1px solid #842029;
  color: #842029;
}

.show--alert {
  animation: show-alert 7s linear;
}

@keyframes show-alert {
  0% {
    right: -28%;
    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      right: -60%;
    }
    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      right: -80%;
    }
  }
  2% {
    right: 1rem;
    opacity: 1;
  }
  5% {
    right: 1rem;
    opacity: 1;
  }
  10% {
    right: 1rem;
    opacity: 1;
  }
  20% {
    right: 1rem;
    opacity: 1;
  }
  30% {
    right: 1rem;
    opacity: 1;
  }
  40% {
    right: 1rem;
    opacity: 1;
  }
  50% {
    right: 1rem;
    opacity: 1;
  }
  60% {
    right: 1rem;
    opacity: 1;
  }
  70% {
    right: 1rem;
    opacity: 1;
  }
  80% {
    right: 1rem;
    opacity: 1;
  }
  90% {
    right: 1rem;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
