@use '../../app.scss' as *;

.contact {
  position: relative;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      column-gap: 3rem;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      width: 80%;
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
  }

  &__title {
    text-align: center;
    font-size: $h3-font-size;
    font-weight: $font-medium;
    margin-bottom: $mb-1-5;
  }

  &__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      justify-content: center;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $sm) {
      grid-template-columns: 1fr;
      column-gap: 1rem;
    }
  }

  &__card {
    background-color: $container-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
    transition: $transition;

    &:hover {
      @include boxHover;
    }

    /* For medium devices ≤ 768px */
    @media screen and (min-width: 921px) {
      &:last-child {
        display: none;
      }
    }

    &--icon {
      font-size: 2rem;
      color: $title-color;
      margin-bottom: $mb-0-25;
    }

    &--title,
    &--data {
      font-size: $small-font-size;
    }

    &--title {
      font-weight: $font-medium;
    }

    &--data {
      color: $primary-text-color;
      display: block;
      margin-bottom: $mb-0-75;
    }
  }

  &__button {
    color: $text-color;
    font-size: $small-font-size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;

    &--icon {
      font-size: 1rem;
      transition: 0.3s;
    }

    &:hover {
      color: $title-color;
      font-weight: 500;
    }

    &:hover &--icon {
      transform: translate(0.25rem);
    }
  }

  &__form {
    width: 360px;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      margin: 0 auto;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &--div {
      position: relative;
      margin-bottom: $mb-2;
      // height: 4rem;
    }

    // &--div.invalid {
    //   // margin-bottom: 2.2rem;
    // }

    &--div.invalid &--input {
      border: 2px solid red;
    }

    &--div.invalid &--tag {
      color: red;
    }

    &--input {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $primary-border-color;
      background: none;
      color: $text-color;
      outline: none;
      border-radius: 0.75rem;
      padding: 1.13rem 1.5rem;
      z-index: 1;
    }

    &--tag {
      position: absolute;
      top: -0.75rem;
      left: 1.25rem;
      font-size: $small-font-size;
      padding: 0.25rem;
      background-color: $section-color;
      color: $primary-text-color;
      z-index: 10;
    }

    &--area {
      // height: 11rem;

      textarea {
        resize: none;
      }
    }

    &--footer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .field--error {
      font-size: small;
      color: rgb(119, 11, 11);
    }

    button:disabled,
    button:disabled:hover,
    button:disabled:active {
      background-color: #ccc;
      color: #292929;
      border-color: #ccc;
      cursor: not-allowed;
    }
  }
}
