@use '../../app.scss' as *;

// ABSOLUTE CENTER
@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// TRANSITION PROPERTY
@mixin transitionProperty {
  transition-duration: 1000ms;
  transition-property: all;
}

.portfolio {
  &__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: $mb-2;

    /* For small devices ≤ $576px */
    @media screen and (max-width: $sm) {
      flex-wrap: wrap;
      column-gap: 0.9rem;
    }

    /* For extra small devices ≤ $350px */
    @media screen and (max-width: $xs) {
      //column-gap: 0.1rem;
    }
  }

  &__item {
    cursor: pointer;
    color: $title-color;
    padding: 0.25rem 0.75rem;
    font-weight: $font-medium;
    border-radius: 0.5rem;
    text-transform: capitalize;

    /* For small devices ≤ $576px */
    @media screen and (max-width: $sm) {
      font-size: $smaller-font-size;
      margin-bottom: 0.5rem;
    }

    &:hover {
      background-color: $title-color;
      color: $container-color;
    }
  }

  &__container {
    max-width: 70% !important;
    display: grid;
    //grid-template-columns: repeat(3, 1fr);
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(
        200px,
        1fr
      );
    gap: 2rem;
    justify-content: center;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      //grid-template-columns: repeat(2, 1fr);
      grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
      gap: 2rem;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      max-width: 90% !important;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      //grid-template-columns: 1fr;
      grid-template-columns: minmax(200px, 1fr);
      max-width: 85% !important;
    }

    /* For extra small devices ≤ 350px */
    @media screen and (max-width: $xs) {
      max-width: 90% !important;
    }
  }

  &__card {
    background-color: $container-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    transition: $transition;

    &:hover {
      @include boxHover;
    }

    &:hover img {
      filter: grayscale(0%);
    }

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      padding: 1rem;
    }
  }

  &__box {
    position: relative;
    width: 100%;
    //max-height: 190px;
    //height: auto;
    overflow: hidden;
    border-radius: 1rem;
    margin-bottom: 1rem;

    /* For extra small devices ≤ 350px */
    @media screen and (max-width: $xs) {
      //max-height: 170px;
    }

    .portfolio--info {
      background-color: rgba(#000, 0.5);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      border-radius: 1rem;

      .caption {
        text-align: center;
        color: $container-color;
        @include absoluteCenter;

        a {
          i {
            font-size: 2rem;
            color: $color-white;
          }

          &:hover {
            opacity: 70%;
          }
        }
      }
    }

    &:hover {
      img {
        transform: scale(1.5);
        @include transitionProperty;
      }

      .portfolio--info {
        opacity: 1;
        visibility: visible;
        @include transitionProperty;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    margin-bottom: $mb-1;
    filter: grayscale(100%);

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      margin-bottom: 0.75rem;
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      width: 100%;
    }
  }

  &__tech {
    display: inline-flex;
    column-gap: 1rem;
    margin-bottom: $mb-1;
    overflow-x: auto;
    padding-bottom: 5px;

    &:hover {
      &::-webkit-scrollbar {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      height: 3px;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: $title-color;
      border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: $text-color;
      border-radius: 15px;
    }

    // &::-webkit-scrollbar-thumb:hover {
    //   background: #555;
    // }

    &--stack {
      font-size: 0.6rem;
      color: $container-color;
      background: $title-color;
      padding: 0.15rem 0.5rem;
      border-radius: 1rem;
    }
  }

  &__title {
    font-size: $normal-font-size;
    font-weight: $font-medium;
    margin-bottom: $mb-2;
  }

  &__links {
    display: block;
    margin-top: auto;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    color: $text-color;
    font-size: $small-font-size;
    column-gap: 0.25rem;
    margin-right: 3rem;
    cursor: pointer;

    &--icon {
      font-size: 1rem;
      transition: 0.4s;
    }

    &:hover {
      color: $title-color;
      font-weight: 500;
    }

    &:hover &--icon {
      transform: translateX(0.25rem);
    }
  }

  /* active portfolio */
  .active-portfolio {
    background-color: $title-color;
    color: $container-color;
  }
}

.pswp {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
