@use '../../app.scss' as *;

.footer {
  background-color: $body-color;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &__container {
    padding: 4rem 0 6rem;
  }

  &__title,
  &__link {
    color: $title-color;
  }

  &__title {
    text-align: center;
    margin-bottom: $mb-2;
    font-weight: $font-bold;
  }

  &__link {
    &:hover {
      color: $title-color-dark;
      font-weight: 700;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: $mb-2;
  }

  &__social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;

    &--link {
      background-color: $title-color;
      color: $container-color;
      font-size: 1.125rem;
      padding: 0.4rem;
      border-radius: 0.5rem;
      display: inline-flex;
      transition: $transition;

      /* For large devices ≤ 1200px */
      @media screen and (max-width: $xl) {
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
      }

      &:hover {
        background-color: $title-color-dark;
        transform: scale(1.25);
      }
    }
  }

  &__copy {
    display: block;
    margin-top: 4.5rem;
    color: $title-color;
    text-align: center;
    font-size: $smaller-font-size;
  }
}
