@use '../../app.scss' as *;

.skills {
  &__container {
    grid-template-columns: repeat(2, 48%);
    column-gap: 3rem;
    justify-content: center;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      row-gap: 2rem;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      grid-template-columns: 1fr;
    }
  }

  &__content {
    background-color: $container-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 2rem;
    border-radius: 1.25rem;
    transition: $transition;

    &:hover {
      @include boxHover;
    }

    &:hover .tool {
      filter: grayscale(0%);
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
      width: 70%;
      margin-left: auto;
      margin-right: auto;

      /* For large devices ≤ 992px */
      @media screen and (max-width: $lg) {
        width: 100%;
      }

      .skills__group {
        grid-template-columns: repeat(2, 45%);

        /* For small devices ≤ 576px */
        @media screen and (max-width: $sm) {
          grid-template-columns: 1fr;
        }
      }
    }

    /* For medium devices ≤ 768px */
    @media screen and (max-width: $md) {
      padding: 1.5rem;
    }
  }

  &__title {
    font-size: $h3-font-size;
    font-weight: $font-medium;
    text-align: center;
    margin-bottom: $mb-1-5;
  }

  &__other {
    &--title {
      font-size: $small-font-size;
      font-weight: $font-normal;
      text-align: center;
      margin-bottom: $mb-1;
      margin-top: $mb-1-5;
    }

    &--tools {
      display: flex;
      justify-content: space-between;
      padding: 0.7rem;
      border-radius: 1rem;
      background: $body-color;

      .tool {
        width: 50px;
        height: 50px;
        filter: grayscale(100%);
        transition: $transition;

        /* For medium devices ≤ 768px */
        @media screen and (max-width: $md) {
          width: 40px;
          height: 40px;
        }

        /* For medium devices ≤ 360px */
        @media screen and (max-width: $xs) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &__group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 45%);
    align-items: flex-start;
    column-gap: 3.5rem;
    row-gap: 1.5rem;
    justify-content: center;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }
  }

  &__data {
    width: 100%;
    display: flex;
    column-gap: 0.5rem;
  }

  .bx-badge-check {
    font-size: 1rem;
    color: $title-color;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title-name {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.7rem;
  }

  &__name {
    font-size: $normal-font-size;
    font-weight: $font-medium;
    line-height: 18px;

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      font-size: $small-font-size;
    }
  }

  &__number {
    color: $primary-text-color;
    line-height: 18px;
  }

  &__bar,
  &__percentage {
    height: 5px;
    border-radius: 0.25rem;
  }

  &__bar {
    width: 100%;
    background-color: #ccc;
    margin-bottom: 0.2rem;
  }

  &__percentage {
    background-color: $title-color;
    display: block;
  }

  &__level {
    color: $primary-text-color;
    font-size: $tiny-font-size;
  }

  &__view-all {
    margin-top: 3rem;
    text-align: center;

    a {
      width: auto;

      .button__icon {
        width: 24px;
        height: 24px;
      }

      svg {
        path {
          fill: $container-color;
        }
      }
    }
  }
}

.tooltip {
  color: $body-color !important;
  border-radius: 0.5rem !important;
  background-color: $title-color !important;
}
