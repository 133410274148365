@use '../../app.scss' as *;

.services {
  &__container {
    grid-template-columns: repeat(4, 250px);
    justify-content: center;
    column-gap: 1.8rem;

    /* For extra large devices ≤ 1200px */
    @media screen and (max-width: $xl) {
      grid-template-columns: repeat(4, 218px);
    }

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      grid-template-columns: repeat(2, 40%);
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      grid-template-columns: 1fr;
    }
  }

  &__contentbox {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background: $container-color;
    padding: 6rem 0 2rem 2.5rem;
    transition: $transition;

    &:hover {
      @include boxHover;
    }

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      padding: 3.5rem 0.5rem 1.25rem 1.5rem;
    }

    /* For small devices ≤ 576px */
    @media screen and (max-width: $sm) {
      padding-right: 6rem;
    }
  }

  &__icon {
    display: block;
    font-size: 2rem;
    color: $title-color;
    margin-bottom: $mb-1;

    /* For large devices ≤ 992px */
    @media screen and (max-width: $lg) {
      font-size: 1.5rem;
    }
  }

  &__title {
    font-size: $h3-font-size;
    margin-bottom: $mb-1;
    font-weight: $font-medium;
  }

  &__button {
    color: $text-color;
    font-size: $small-font-size;
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;

    &--icon {
      font-size: 1rem;
      transition: 0.3s;
    }

    &:hover {
      color: $title-color;
      font-weight: 500;
    }

    &:hover &--icon {
      transform: translateX(0.25rem);
    }
  }

  // Services Modal

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: $z-modal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;

    &--content {
      width: 35rem;
      max-height: 90%;
      position: relative;
      background-color: $container-color;
      padding: 4.5rem 3rem 3.5rem 3rem;
      border-radius: 1.5rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0.3em;
      }

      /* For medium devices ≤ 768px */
      @media screen and (max-width: $md) {
        width: 70%;
      }

      /* For small devices ≤ 576px */
      @media screen and (max-width: $sm) {
        width: 95%;
        padding: 3.5rem 2rem 2rem 2rem;
      }

      /* For extra small devices ≤ 350px */
      // @media screen and (max-width: $xs) {
      //   width: 95%;
      // }
    }

    &--close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      font-size: 1.5rem;
      color: $title-color;
      cursor: pointer;
    }

    // &--title,
    // &--description {
    //   text-align: center;
    // }

    &--title {
      font-size: $h3-font-size;
      font-weight: $font-medium;
      margin-bottom: $mb-1;
      text-align: center;
    }

    &--description {
      font-size: $small-font-size;
      color: $primary-text-color;
      padding: 0 2.3rem;
      margin-bottom: $mb-2;
      text-align: center;

      /* For medium devices ≤ 768px */
      @media screen and (max-width: $md) {
        padding: 0;
      }

      /* For extra small devices ≤ 350px */
      @media screen and (max-width: $xs) {
        font-size: $smaller-font-size;
      }
    }

    &--services {
      row-gap: 0.75rem;
    }

    &--service {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }

    &--icon {
      color: $title-color;
      font-size: 1.1rem;
    }

    &--info {
      font-size: $small-font-size;
      color: $primary-text-color;

      /* For extra small devices ≤ 350px */
      @media screen and (max-width: $xs) {
        font-size: $smaller-font-size;
      }
    }
  }
}

/* Active Modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}
